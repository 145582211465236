$(function() {
    $('.apply-it').on('click', function () {
        $('.apply').addClass('show');
    });
    $('.apply').on('click', '.close-form', function(){
        $('.apply').removeClass('show');
    });
    $('#apl-form').on('submit', function(evt){
        evt.preventDefault();
        var _ret = true;
        $('#apl-form').find('input').each(function(){
            if($(this).attr('type') === 'tel'){
                if(!/^1[0-9]{10}$/.test($(this).val())){
                    $(this).addClass('shake');
                    _ret = false;
                }
            }else{
                if($.trim($(this).val()) === ''){
                    $(this).addClass('shake');
                    _ret = false;
                }
            }
        });
        setTimeout(function(){
            $('#apl-form').find('input').removeClass('shake');
        }, 500);
        if(_ret){
            $.ajax({
                url: 'https://api.meizitop.com/api/v1/member/register',
                type: 'POST',
                data: {
                    mobile: $('input[name=mobile]').val(),
                    nick_name: $('input[name=name]').val(),
                    brand_name: $('input[name=brand]').val(),
                    address: $('input[name=address]').val(),
                    is_apply: "1"
                },
                dataType: 'json',
                success: function(res) {
                    if(res.code == '200'){
                        alert('您已提交成功，稍后会有业务人员联系您。');
                        $('.apply-form').find('.close-form').trigger('click');
                    }else{
                        alert('提交失败:'+res.message);
                    }
                },
                error: function(){
                    alert('提交失败，请稍后重试');
                }
            });
        }
    });

    $(document).on('scroll', function(){
        var _scrollTop = $(document).scrollTop();
        var _windowHeight = $(window).height();
        if(_scrollTop > 70){
            $('.navbar').addClass('bg-dark');
            if(_windowHeight > 768){
                $('.navbar-nav a').removeClass('text-body');
                $('.navbar-nav a').addClass('text-white');
            }
        }else{
            $('.navbar').removeClass('bg-dark');
            $('.navbar-nav a').removeClass('text-white');
            $('.navbar-nav a').addClass('text-body');
        }

        // $('.section').each(function(){
        //     if(_scrollTop + _windowHeight > $(this).offset().top){
        //         $(this).find('.animated').addClass('fadeInUp');
        //     }
        // });
    });
})


// The module cache
var installedModules = {};

// The require function
function require(moduleId) {
	// Check if module is in cache
	if(installedModules[moduleId])
		return installedModules[moduleId].exports;
	
	// Create a new module (and put it into the cache)
	var module = installedModules[moduleId] = {
		exports: {},
		id: moduleId,
		loaded: false
	};
	
	// Execute the module function
	modules[moduleId].call(module.exports, module, module.exports, require);
	
	// Flag the module as loaded
	module.loaded = true;
	
	// Return the exports of the module
	return module.exports;
}


// expose the modules object (__webpack_modules__)
require.modules = modules;

// expose the module cache
require.cache = installedModules;

// __webpack_public_path__
require.p = "dev/js/";


// Load entry module and return exports
return require(0);